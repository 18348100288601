<template>
  <div class="appsContainer__app" v-if="app">
    <div class="appsContainer__appIcon">
      <div class="appsContainer__icon"><img class="appsContainer__image" :src="app.icon" :alt="app.key" /></div>
      <p v-if="showname" class="appsContainer__appName">{{ app.name }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["app", "showname"],
  };
</script>

<style lang="scss">
  .appsContainer {
    &__app {
      text-align: center;
      cursor: pointer;
    }
    &__appIcon {
      font-size: 50px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      width: 90px;
      height: 100px;
      margin-top: 20px;
    }
    &__image {
      width: 100%;
      height: 74px;
    }
    &__appName {
      font-size: 12px;
      text-align: center;
      font-family: $newFont_semiBold;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
</style>
